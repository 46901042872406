import {
  Divider,
  Space,
  message,
  Button,
  Modal,
  Card,
  Row,
  Col,
  Image,
  Spin,
} from "antd";
import React from "react";
import HeaderSection from "../../components/HeaderSection";
import { useParams, useNavigate } from "react-router-dom";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import useDetailBreadcrumbs from "../../hooks/useDetailBreadcrumbs";
import DetailItem from "../../components/DetailItem";
import {
  EApplicationServiceType,
  initialPackageSubscription,
  PackageSubscriptionProperties,
} from "../../types/packageSubscription.type";
import { formatDate } from "../../helpers/constant";
import {
  showIntervalInMonthText,
  showPackageSubscriptionName,
} from "../../helpers/subscription";
import { priceFormat } from "../../helpers/priceFormat";

interface ILocation {
  packageSubscriptionId: string;
  levelSubscriptionId: string;
}

interface ResponseProps
  extends BaseResponseProps<PackageSubscriptionProperties> {}

const PackageSubscriptionDetail = () => {
  const navigate = useNavigate();
  const { packageSubscriptionId, levelSubscriptionId } = useParams<
    keyof ILocation
  >() as ILocation;
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [packageSubscription, setPackageSubscription] =
    React.useState<PackageSubscriptionProperties>(initialPackageSubscription);
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [confirmationType, setConfirmationType] = React.useState<string>("");

  React.useEffect(() => {
    const fetchPackageSubscription = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<ResponseProps>(
          "/master-package-subscription/" + packageSubscriptionId
        );

        const resLevel = await httpRequest.get<any>(
          "/master-level-subscription/" + levelSubscriptionId
        );

        // console.log(res.data.payload);
        setPackageSubscription(res.data.payload);

        const bcDetails = [
          {
            field: "levelSubscriptionId",
            value: levelSubscriptionId,
            label: resLevel.data.payload.name,
          },
          {
            field: "packageSubscriptionId",
            value: packageSubscriptionId,
            label: showPackageSubscriptionName(res.data.payload),
          },
        ];
        setBreadcrumbDetails(bcDetails);

        setIsLoading(false);
      } catch (error: any) {
        message.error(error);
        setIsLoading(false);
      }
    };
    fetchPackageSubscription();

    // eslint-disable-next-line
  }, [packageSubscriptionId]);

  const handleClickEdit = () => {
    navigate(
      `/subscription-level/${packageSubscriptionId}/${levelSubscriptionId}/edit`
    );
    // navigate("/subscription-package/" + packageSubscriptionId + "/edit");
  };

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={packageSubscription.aliasName}
        subtitle="Manage Subscription Package"
        rightAction={
          <Space>
            {/* <Button onClick={() => navigate("/subscription-package")}>
              Cancel
            </Button> */}
            <Button type="primary" onClick={handleClickEdit}>
              Edit
            </Button>
          </Space>
        }
      />
      <Spin spinning={isLoading}>
        <Card bordered={false}>
          <Row gutter={[24, 0]}>
            <Col span={10}>
              <DetailItem
                label="Alias Name"
                value={packageSubscription.aliasName}
              />
              <DetailItem
                label="Interval"
                value={showIntervalInMonthText(
                  packageSubscription.intervalInMonth
                )}
              />
              <DetailItem
                label="Term and Condition"
                children={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: packageSubscription.tnC,
                    }}
                  ></div>
                }
              />
              <DetailItem
                label="Biaya Admin"
                value={
                  priceFormat(packageSubscription.administrativeCosts) || 0
                }
              />
              <DetailItem
                label="Pajak Hiburan"
                children={
                  <div>
                    {packageSubscription.entertainmentTax !== null
                      ? `${packageSubscription.entertainmentTax}%`
                      : "0%"}
                  </div>
                }
              />
              <DetailItem
                label="PPN"
                children={
                  <div>
                    {packageSubscription.ppn !== null &&
                    packageSubscription.ppn !== 0
                      ? `${packageSubscription.ppn}%`
                      : "0%"}
                  </div>
                }
              />
              <DetailItem
                label="Jasa Aplikasi"
                children={
                  <div>
                    {packageSubscription.applicationServiceType ===
                      EApplicationServiceType.NOMINAL ||
                    packageSubscription.applicationServiceType === "" ||
                    packageSubscription.applicationService === null
                      ? priceFormat(packageSubscription.applicationService)
                      : `${packageSubscription.applicationService}%`}
                  </div>
                }
              />
              <DetailItem
                label="Status"
                value={packageSubscription.isPublished ? "Active" : "Inactive"}
              />
              <DetailItem
                label="Created At"
                value={formatDate(packageSubscription.createdAt)}
              />
              <DetailItem
                label="Updated At"
                value={formatDate(packageSubscription.updatedAt)}
              />
            </Col>
          </Row>
        </Card>
        {confirmationType === "description" ? (
          <Modal
            title="Description"
            open={isModalVisible}
            onCancel={() => {
              setIsModalVisible(false);
            }}
            footer={null}
          >
            <div style={{ textAlign: "center" }}>{packageSubscription.tnC}</div>
          </Modal>
        ) : (
          <></>
        )}
      </Spin>
    </React.Fragment>
  );
};

export default PackageSubscriptionDetail;
