import {
  Form,
  Input,
  message,
  Radio,
  Modal,
  Space,
  Button,
  Card,
  Spin,
  InputNumber,
  Row,
  Col,
} from "antd";
import React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import HeaderSection from "../../components/HeaderSection";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import UploadComponent from "../../components/UploadComponent";
import { generateFormRules } from "../../helpers/formRules";
import { getErrorMessage } from "../../helpers/errorHandler";
import styled from "styled-components";
import { getImageSize } from "react-image-size";
import {
  initialSubscriptionLevel,
  SubscriptionLevelProperties,
} from "../../types/subscriptionLevel.type";
import ColorPicker from "../../components/ColorPicker";
import AuthContext from "../../context/AuthContext";
import useDetailBreadcrumbs from "../../hooks/useDetailBreadcrumbs";

interface ILocation {
  levelSubscriptionId: string;
}

interface ResponseProps
  extends BaseResponseProps<SubscriptionLevelProperties> {}

const SubscriptionLevelEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth, setAuth } = React.useContext(AuthContext);
  const { levelSubscriptionId } = useParams<keyof ILocation>() as ILocation;
  const [form] = Form.useForm();

  const { setBreadcrumbDetails } = useDetailBreadcrumbs();

  const { TextArea } = Input;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [selectedImage, setSelectedImage] = React.useState<File>();
  const [subscriptionLevelData, setSubscriptionLevelData] =
    React.useState<SubscriptionLevelProperties>(initialSubscriptionLevel);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const createSubscriptionLevel = async (
    value: SubscriptionLevelProperties
  ) => {
    try {
      setIsLoadingAction(true);
      if (selectedImage) {
        const url = URL.createObjectURL(selectedImage);

        const { width, height } = await getImageSize(url);
      }

      const formData = {
        levelSubscriptionId: value.levelSubscriptionId,
        name: value.name,
        description: value.description,
        startColor: subscriptionLevelData.startColor
          ? subscriptionLevelData.startColor
          : "#264284",
        endColor: subscriptionLevelData.endColor
          ? subscriptionLevelData.endColor
          : "#2198CE",
        level: value.level,
        isPublished: false,
        createdByUserId: auth.user.userId,
      };

      console.log(value.isPublished);

      const post = await httpRequest.post(
        "/master-level-subscription",
        formData
      );

      if (selectedImage) {
        let formData = new FormData();
        const postSubscriptionLevelId = post.data.payload.levelSubscriptionId;
        formData.append("image", selectedImage);
        await httpRequest.put(
          "/master-level-subscription/" + postSubscriptionLevelId + "/photo",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
      message.success("Success create " + value.name);

      navigate("/subscription-level");
    } catch (error) {
      if (getErrorMessage(error).includes("name must be unique")) {
        message.error("Subscription Name Sudah Terdaftar");
      } else if (getErrorMessage(error).includes("level must be unique")) {
        message.error("Level Sudah Terdaftar");
      } else {
        message.error(getErrorMessage(error));
      }
      setIsLoadingAction(false);
    }
  };

  const updateSubscriptionLevel = async (
    value: SubscriptionLevelProperties
  ) => {
    try {
      setIsLoadingAction(true);

      if (selectedImage) {
        const url = URL.createObjectURL(selectedImage);

        const { width, height } = await getImageSize(url);

        URL.revokeObjectURL(url);
        if (width > 112 || height > 112) {
          setIsLoadingAction(false);
          return message.error("Ukuran Foto Tidak Sesuai Rekomendasi");
        }
      }

      if (selectedImage) {
        let formData = new FormData();
        formData.append("image", selectedImage);
        await httpRequest.put(
          "/master-level-subscription/" + levelSubscriptionId + "/photo",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      const formData = {
        levelSubscriptionId: value.levelSubscriptionId,
        name: value.name,
        description: value.description,
        startColor: subscriptionLevelData.startColor
          ? subscriptionLevelData.startColor
          : "#264284",
        endColor: subscriptionLevelData.endColor
          ? subscriptionLevelData.endColor
          : "#2198CE",
        level: value.level,
        isPublished: value.isPublished,
        createdByUserId: auth.user.userId,
      };
      await httpRequest.patch(
        "/master-level-subscription/" + levelSubscriptionId,
        formData
      );

      message.success("Success update " + value.name + " data");
      navigate("/subscription-level");
    } catch (error) {
      if (getErrorMessage(error).includes("name must be unique")) {
        message.error("Subscription Name Sudah Terdaftar");
      } else if (getErrorMessage(error).includes("level must be unique")) {
        message.error("Level Sudah Terdaftar");
      } else {
        message.error(getErrorMessage(error));
      }
      setIsLoadingAction(false);
    }
  };

  const handleSubmit = async (values: SubscriptionLevelProperties) => {
    if (levelSubscriptionId) {
      updateSubscriptionLevel(values);
    } else {
      createSubscriptionLevel(values);
    }
  };

  React.useEffect(() => {
    if (levelSubscriptionId) {
      const fetchCustomer = async () => {
        try {
          setIsLoading(true);

          const res = await httpRequest.get<ResponseProps>(
            "/master-level-subscription/" + levelSubscriptionId
          );
          setSubscriptionLevelData(res.data.payload);

          const bcEdit = [
            {
              field: "levelSubscriptionId",
              value: levelSubscriptionId,
              label: res.data.payload.name,
            },
          ];
          setBreadcrumbDetails(bcEdit);

          form.setFieldsValue(res.data.payload);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      };

      fetchCustomer();
    }

    // eslint-disable-next-line
  }, [levelSubscriptionId, location]);

  const str2bool = (value: string) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  const showModalBack = () => {
    setIsModalOpen(true);
  };

  const handleOkModalBack = () => {
    navigate(-1);
    setIsModalOpen(false);
  };

  const handleCancelModalBack = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        backAction={showModalBack}
        title={(levelSubscriptionId ? "Edit" : "Add") + " Subscription Level"}
        subtitle={
          levelSubscriptionId
            ? "Manage subscription level data"
            : "Create new subscription level"
        }
        rightAction={
          <Space>
            <Button
              onClick={() => {
                showModalBack();
                // navigate(-1);
              }}
            >
              Cancel
            </Button>
            <Button
              loading={isLoadingAction}
              type="primary"
              onClick={() => form.submit()}
            >
              Save
            </Button>
          </Space>
        }
      />
      <Spin spinning={isLoading}>
        <Card bordered={false}>
          <Form
            form={form}
            name="subscriptionLevel"
            layout="vertical"
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <UploadComponent
              title="Level Icon"
              additionalHint="Extensi JPG, PNG. Rekomendasi ukuran 112 px x 112 px"
              currentPreview={subscriptionLevelData.icon}
              onSelectFile={setSelectedImage}
            />
            <Form.Item
              style={{ marginTop: 20 }}
              label="Subscription Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "wajib diisi, data tidak boleh kosong",
                },
              ]}
            >
              <Input
                onChange={(event) =>
                  setSubscriptionLevelData({
                    ...subscriptionLevelData,
                    name: event.target.value,
                  })
                }
                placeholder="Input subscription name"
              />
            </Form.Item>

            <Form.Item label="Description" name="description">
              <TextArea
                rows={4}
                onChange={(event) =>
                  setSubscriptionLevelData({
                    ...subscriptionLevelData,
                    description: event.target.value,
                  })
                }
                placeholder="Input description"
              />
            </Form.Item>

            <Form.Item
              style={{ marginTop: 20 }}
              label="Level"
              name="level"
              rules={[
                {
                  required: true,
                  message: "wajib diisi, data tidak boleh kosong",
                },
              ]}
            >
              <InputNumber
                defaultValue={subscriptionLevelData.level || ""}
                min={0}
                max={100}
                onChange={(value: any) => {
                  console.info("value", value);
                  setSubscriptionLevelData({
                    ...subscriptionLevelData,
                    level: value ? Number(value) : 0,
                  });
                }}
                placeholder="Input level"
              />
            </Form.Item>

            <Space direction="vertical">
              <Row align="middle" gutter={16}>
                <Col span={3}>Warna Start *</Col>
                <Col>
                  <ColorPicker
                    value={subscriptionLevelData.startColor || "#264284"}
                    onChange={(val) =>
                      setSubscriptionLevelData({
                        ...subscriptionLevelData,
                        startColor: val,
                      })
                    }
                  />
                </Col>
              </Row>

              <Row align="middle" gutter={16}>
                <Col span={3}>Warna End *</Col>
                <Col>
                  <ColorPicker
                    value={subscriptionLevelData.endColor || "#2198CE"}
                    onChange={(val) =>
                      setSubscriptionLevelData({
                        ...subscriptionLevelData,
                        endColor: val,
                      })
                    }
                  />
                </Col>
              </Row>
            </Space>
            {/* <Form.Item
              style={{ marginTop: 10 }}
              label="Publish"
              name="isPublished"
            >
              <Radio.Group defaultValue={true}>
                <CustomRadio value={str2bool("true")}>Publish</CustomRadio>
                <CustomRadio value={str2bool("false")}>
                  Don't Publish
                </CustomRadio>
              </Radio.Group>
            </Form.Item> */}
          </Form>
        </Card>
      </Spin>

      <Modal
        title="Cancel Confirmation"
        open={isModalOpen}
        onOk={handleOkModalBack}
        onCancel={handleCancelModalBack}
      >
        <p>Are you sure ? Your data won't be save.</p>
      </Modal>

      <div style={{ height: 300 }}></div>
    </React.Fragment>
  );
};

export default SubscriptionLevelEdit;

const CustomRadio = styled(Radio)`
  margin-right: 1rem;
  .ant-radio-checked .ant-radio-inner {
    border-color: #264284;
    box-shadow: none;
  }
  .ant-radio:hover .ant-radio-inner {
    background-color: white;
  }
  .ant-radio-checked .ant-radio-inner:after {
    background-color: #264284;
  }
`;
