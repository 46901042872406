export type SubscriptionLevelProperties = {
  levelSubscriptionId?: string;
  name: string;
  startColor: string;
  endColor: string;
  level: number;
  icon: string;
  description: string;
  isPublished: boolean;
  isSoftDelete: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  createdByUserId: string;

};

export const initialSubscriptionLevel: SubscriptionLevelProperties = {
  levelSubscriptionId: "",
  name: "",
  startColor: "",
  endColor: "",
  level: 0,
  icon: "",
  description: "",
  isPublished: true,
  isSoftDelete: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  createdByUserId: "",
}