import { PackageSubscriptionProperties } from "./packageSubscription.type";

export type RoleProperties = {
  roleId: string;
  roleName: string;
  roleDescription?: string;
  permissions: any;
  status: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type TagProperties = {
  tagId?: string;
  tagName: string;
  description?: string;
  status: string;
  createdAt?: Date;
  updatedAt?: Date;
  events?: EventProperties[];
};

export type EventProperties = {
  eventId: string;
  venueId: string;
  streamKey: string;
  eventTitle: string;
  description: string;
  isSpecial: boolean;
  isSoftDelete: boolean;
  eventCode?: string;
  mainTalentId: string;
  rerunVideoURL?: string;
  startAt: Date;
  endAt: Date;
  rerunStartAt?: Date;
  rerunDate?: Date[];
  rerunEndAt?: Date;
  publishedAt?: Date;
  status: boolean;
  coverImage?: string;
  venueLayout?: string;
  taxPPN?: number;
  taxPajakHiburan?: number;
  rerunMaxDuration?: number;
  adminFee?: number;
  applicationService?: number;
  applicationServiceType?: string;
  createdAt?: Date;
  updatedAt?: Date;
  createdByUserId: string;
  mainTalent: TalentProperties;
  createdBy?: UserProperties;
  venue: VenueProperties;
  eventParticipants?: EventParticipantProperties[];
  eventVariants: EventVariantProperties[];
  tags?: TagProperties[];
  talents?: TalentProperties[];
  eventDate?: Date;
  eventTime?: Date[];
  eventPublishTime?: Date;
};

export type CustomerProperties = {
  name?: string;
  code?: string;
  phone?: string;
  email?: string;
  address?: string;
};

export enum EImportDataStatus {
  WAITING = "WAITING",
  STARTED = "STARTED",
  ERROR = "ERROR",
  FINISHED = "FINISHED",
}

export enum EImportDataLogAction {
  VALIDATE = "VALIDATE",
  CREATE_CUSTOMER = "CREATE_CUSTOMER",
  CREATE_TRANSACTION = "CREATE_TRANSACTION",
}

export type ImportProperties = {
  importId: string;
  fileName: string;
  createdAt?: Date;
  updatedAt?: Date;
  countWaiting?: number;
  countStarted?: number;
  countError?: number;
  countFinished?: number;
  importDatas: ImportDataProperties[];
};

export type ImportDataProperties = {
  importItemId: string;
  importId: string;
  data: any;
  status: EImportDataStatus;
  importDataLogs: ImportDataLogProperties[];
  createdAt?: Date;
  updatedAt?: Date;
};

export type ImportDataLogProperties = {
  logId: string;
  importItemId: string;
  data: any;
  action: EImportDataLogAction;
  createdAt?: Date;
  updatedAt?: Date;
};

export type PreviewDataProperties = {
  refTransactionCode: string;
  buyerDetail: CustomerProperties;
  items: PreviewDataItemProperties[];
};

export type PreviewDataItemProperties = {
  eventVariantCode: string;
  qty: number;
};

export type TransactionProperties = {
  transactionId: string;
  transactionCode: string;
  grossSubtotal: number;
  totalAdminFee?: number;
  refTransactionCode?: string;
  totalDiscount?: number;
  totalPPN?: number;
  ppnNote?: string;
  totalPajakHiburan?: number;
  grandTotal: number;
  buyerId: string;
  buyerDetail: CustomerProperties;
  createdByUserId: string;
  transactionStatus: ETransactionStatus;
  createdAt?: Date;
  updatedAt?: Date;
  transactionItems: TransactionItemProperties[];
};

export type TransactionItemProperties = {
  transactionItemId: string;
  transactionId: string;
  eventId: string;
  metaEvent: EventProperties;
  eventVariantId: string;
  metaEventVariant: EventVariantProperties;
  eventVariant?: EventVariantProperties;
  pricePerUnit: number;
  qty: number;
  totalPrice: number;
  discount?: number;
  ppn?: number;
  pajakHiburan?: number;
  adminFee?: number;
  totalFinalPrice: number;
  createdAt?: Date;
  updatedAt?: Date;
};

export type TalentProperties = {
  talentId: string;
  talentName: string;
  description: string;
  image: string;
  isPublished: boolean;
  isSoftDelete: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  events?: EventProperties[];
  eventMainTalents?: EventProperties[];
};

export type ReviewProperties = {
  reviewId: string;
  eventId: string;
  eventVariantId: string;
  userId: string;
  status: string;
  rating: number;
  message?: string;
  isTopReview?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  event?: EventProperties;
  eventVariant?: EventVariantProperties;
  user?: UserProperties;
};

export type TicketClassProperties = {
  ticketClassId: string;
  ticketClassName: string;
  description: string;
  status: string;
  createdAt?: Date;
  updatedAt?: Date;
  eventVariants?: EventVariantProperties[];
};
export type EventVariantProperties = {
  eventVariantId: string;
  variantName?: string;
  eventId: string;
  eventVariantCode: any;
  ticketClassId: string;
  levelSubscriptionId?: string;
  isCanRerun?: boolean;
  saleType: ESaleType;
  eventType: EEventType;
  startAt: Date;
  endAt: Date;
  price: number;
  finalPrice: number;
  cmsFinalPrice: number;
  discountType?: EDiscountType | any;
  discountValue?: number;
  discountStartAt?: Date;
  discountEndAt?: Date;
  maxStock: number;
  currentStock: number;
  ticketClass: TicketClassProperties;
  variantDate?: Date[];
  discountDate?: Date[];
  masterLevelSubscription: MasterLevelSubscriptionProperties;
  createdAt?: Date;
  updatedAt?: Date;
  event: EventProperties;
};
export type EventParticipantProperties = {};

export type EUserStatus = "active" | "inactive";

export type EStatus = "active" | "inactive";

export enum ETransactionStatus {
  WAITING_PAYMENT = "WAITING_PAYMENT",
  PAID_FULL = "PAID_FULL",
  COMPLETED = "COMPLETED",
  CANCELLED_BY_SYSTEM = "CANCELLED_BY_SYSTEM",
  CANCELLED_BY_USER = "CANCELLED_BY_USER",
}

export type UserProperties = {
  userId: string;
  userCode?: string;
  name: string;
  email: string;
  phone: string;
  isSubscribed?: boolean;
  birthdate?: Date;
  birthcity?: string;
  phoneCountryCode?: string;
  gender?: "male" | "female";
  province?: string;
  city?: string;
  address?: string;
  profilePic?: string;
  status: string;
  updatedAt?: Date;
  createdAt?: Date;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  nickName?: string;
  username?: string;
  nik?: string;
  roles: RoleProperties[];
  isEmailVerified?: boolean;
  isPhoneVerified?: boolean;

  packageSubscriptionId?: string;
  packageSubscription?: PackageSubscriptionProperties;
  subscriptionTransactionId?: string;
  subscriptionStarAt?: Date;
  subscriptionEndAt?: Date;
  subscriptionNextReminderAt?: Date;
  subscriptionNextReminderDays?: number;
};

export type VenueProperties = {
  venueId: string;
  venueName: string;
  venueCode?: string;
  address: string;
  city: string;
  province: string;
  status: string;
  updatedAt?: Date;
  createdAt?: Date;
  postCode?: string;
  mapUrl?: string;
  coordinate?: string;
  events?: EventProperties[];
};

export type VoucherProperties = {
  voucherId: string;
  voucherCode: string;
  voucherName: string;
  voucherVisibility: string;
  currentQuota: number;
  maxQuota: number;
  description: string;
  discountType: string;
  discountValue: number;
  maxDiscountValue: number;
  requirement: any;
  imageUrl?: string;
  isForSpesificUser: boolean;
  isForSpesificEvent: boolean;
  isLimitedRedemption: boolean;
  status: boolean;
  limitMaxUsePerPerson: number;
  validStartAt: Date;
  validEndAt: Date;
  publishedAt: Date;
  updatedAt: Date;
  createdAt: Date;
  events?: EventProperties[];
  eventVariants?: EventVariantProperties[];
  users?: UserProperties[];
};

export type MasterLevelSubscriptionProperties = {
  levelSubscriptionId: string;
  name: string;
  startColor: string;
  endColor: string;
  level: number;
  icon: string;
  description: string;
  isPublished: boolean;
  isSoftDelete: boolean;
  createdByUserId: string;
  createdAt: Date;
  updatedAt: Date;
};

export interface ICreateUser extends UserProperties {
  password?: string;
}

export type IChangePassword = {
  oldPassword: "";
  newPassword: "";
  retypePassword: "";
};

type IRoleList = {
  [key: string]: string;
};

export const RoleList: IRoleList = {
  admin: "Super Admin",
  admin_ecommerce: "Admin ECommerce",
  admin_marketing: "Admin Marketing",
  customer: "Customer",
};

export enum ETicketClass {
  VIP = "VIP",
  REGULER = "REGULER",
}

export enum ESaleType {
  PRESALE = "PRESALE",
  SALE = "SALE",
}

export enum EEventType {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
}

export enum EDiscountType {
  NOMINAL = "NOMINAL",
  PERCENTAGE = "PERCENTAGE",
}
export const initialUser: UserProperties = {
  userId: "",
  name: "",
  email: "",
  phone: "",
  gender: "male",
  province: "",
  city: "",
  status: "inactive",
  firstName: "",
  middleName: "",
  lastName: "",
  roles: [],
};

export const initialRole: RoleProperties = {
  roleId: "",
  roleName: "",
  roleDescription: "",
  status: "active",
  permissions: "",
};

export const initialVenue: VenueProperties = {
  venueId: "",
  venueName: "",
  address: "",
  city: "",
  province: "",
  status: "active",
  updatedAt: new Date(),
  createdAt: new Date(),
  postCode: "",
  mapUrl: "",
  events: [],
};

export const initialVoucher: VoucherProperties = {
  voucherId: "",
  voucherCode: "",
  voucherName: "",
  voucherVisibility: "",
  description: "",
  currentQuota: 0,
  maxQuota: 0,
  discountType: "0",
  discountValue: 0,
  maxDiscountValue: 0,
  requirement: {
    minimumOrder: 0,
  },
  isForSpesificEvent: false,
  isForSpesificUser: false,
  isLimitedRedemption: false,
  updatedAt: new Date(),
  createdAt: new Date(),
  publishedAt: new Date(),
  validEndAt: new Date(),
  validStartAt: new Date(),
  limitMaxUsePerPerson: 1,
  status: true,
  events: [],
  users: [],
};

export const initialTicketClass: TicketClassProperties = {
  ticketClassId: "",
  ticketClassName: "",
  description: "",
  status: "inactive",
  updatedAt: new Date(),
  createdAt: new Date(),
  eventVariants: [],
};

export const initialTalent: TalentProperties = {
  talentId: "",
  talentName: "",
  description: "",
  image: "",
  isPublished: false,
  isSoftDelete: false,
  events: [],
};

export const initialEvent: EventProperties = {
  eventId: "",
  venueId: "",
  streamKey: "",
  eventTitle: "",
  description: "",
  isSpecial: false,
  isSoftDelete: false,
  mainTalentId: "",
  applicationService: 0,
  applicationServiceType: "",
  startAt: new Date(),
  endAt: new Date(),
  publishedAt: new Date(),
  status: false,
  coverImage: "",
  createdByUserId: "",
  venue: initialVenue,
  mainTalent: initialTalent,
  eventParticipants: [],
  eventVariants: [],
  tags: [],
  talents: [],
};

export const initialMasterLevelSubscription: MasterLevelSubscriptionProperties =
  {
    levelSubscriptionId: "",
    name: "",
    startColor: "",
    endColor: "",
    level: 0,
    icon: "",
    description: "",
    isPublished: true,
    isSoftDelete: false,
    createdByUserId: "",
    createdAt: new Date(),
    updatedAt: new Date(),
  };

export const initialEventVariant: EventVariantProperties = {
  eventVariantId: "",
  variantName: "",
  eventId: "",
  ticketClassId: "",
  eventVariantCode: "",
  levelSubscriptionId: "",
  saleType: ESaleType.SALE,
  eventType: EEventType.OFFLINE,
  startAt: new Date(),
  endAt: new Date(),
  price: 0,
  finalPrice: 0,
  cmsFinalPrice: 0,
  discountType: EDiscountType.NOMINAL,
  discountValue: 0,
  maxStock: 0,
  currentStock: 0,
  ticketClass: initialTicketClass,
  event: initialEvent,
  masterLevelSubscription: initialMasterLevelSubscription,
};

export const initialTag: TagProperties = {
  tagId: "",
  tagName: "",
  description: "",
  status: "active",
  events: [],
};

export const initialReview: ReviewProperties = {
  reviewId: "",
  eventId: "",
  eventVariantId: "",
  userId: "",
  status: "",
  rating: 0,
  message: "",
  user: initialUser,
  event: initialEvent,
  eventVariant: initialEventVariant,
};

export const initialCustomer: CustomerProperties = {
  name: "",
  email: "",
  phone: "",
  address: "",
};

export const initialTransactionItem: TransactionItemProperties = {
  transactionItemId: "",
  transactionId: "",
  eventId: "",
  metaEvent: initialEvent,
  eventVariantId: "",
  metaEventVariant: initialEventVariant,
  pricePerUnit: 0,
  qty: 0,
  totalPrice: 0,
  discount: 0,
  ppn: 0,
  pajakHiburan: 0,
  adminFee: 0,
  totalFinalPrice: 0,
};

export const initialTransaction: TransactionProperties = {
  transactionId: "",
  transactionCode: "",
  grossSubtotal: 0,
  totalAdminFee: 0,
  totalDiscount: 0,
  totalPPN: 0,
  ppnNote: "",
  totalPajakHiburan: 0,
  grandTotal: 0,
  buyerId: "",
  buyerDetail: initialCustomer,
  createdByUserId: "",
  transactionStatus: ETransactionStatus.WAITING_PAYMENT,
  transactionItems: [initialTransactionItem],
};

export const initialImportData: ImportDataProperties = {
  importId: "",
  importItemId: "",
  data: "",
  status: EImportDataStatus.STARTED,
  importDataLogs: [],
};

export const initialImport: ImportProperties = {
  importId: "",
  fileName: "",
  importDatas: [],
};
