import React from "react";
import useAuth from "../hooks/useAuth";
import { Navigate, Outlet } from "react-router-dom";
import { message } from "antd";

const PublicRoute: React.FC = () => {
  const { isLoggedIn, user, logout } = useAuth();

  //   if (user && isLoggedIn) {
  //     if (
  //       user.roles
  //         .map((item) => item.permissions.AUTH.LOGIN_CMS_ADMIN.value)
  //         .includes(true)
  //     ) {
  //       return <Navigate to="/dashboard" replace />;
  //     } else {
  //       message.error("Your account don't have permission to login.");
  //       logout();
  //     }
  //   }

  return <Outlet />;
};
export default PublicRoute;
