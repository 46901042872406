import { PackageSubscriptionProperties } from '../types/packageSubscription.type';

export function showPackageSubscriptionName(pkg: PackageSubscriptionProperties, mode?: 'default' | 'full', lang?: 'id' | 'en') {
  if (mode === 'full') {
    let name1 = showLevelPlusInterval(pkg, lang);
    let name2 = pkg.aliasName;

    return name1 + '(' + name2 +  ')';
  } else {
    if (pkg.aliasName) {
      return pkg.aliasName;
    } else {
      return showLevelPlusInterval(pkg, lang);
    }
  }
}

function showLevelPlusInterval(pkg: PackageSubscriptionProperties, lang?: 'id' | 'en') {
  let name1 = '';
  if (pkg.levelSubscription) {
    name1 = `${pkg.levelSubscription?.name} - ${pkg.intervalInMonth} ${
      lang === 'en' ? 'months' : 'bulan'
    }`;
  } else {
    name1 = showIntervalInMonthText(pkg.intervalInMonth, lang);
  }
  return name1;
}

export function showIntervalInMonthTextPlusAliasName(
  intervalInMonth: number,
  aliasName?: string,
  lang?: 'id' | 'en'
) {
  let name1 = `${intervalInMonth} ${lang === 'en' ? 'months' : 'bulan'}`;
  if (aliasName) {
    name1 = name1 + '(' + aliasName + ')'
  }
  return name1;
}

export function showIntervalInMonthText(intervalInMonth: number, lang?: 'id' | 'en') {
  let name1 = `${intervalInMonth} ${lang === 'en' ? 'months' : 'bulan'}`;
  return name1;
}
