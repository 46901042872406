import React from 'react';
import {
  Typography
} from 'antd';

type Props = {
  label: string;
  value?: string | React.ReactNode;
  children?: React.ReactNode;
}
const { Text } = Typography;
const DetailItem: React.FC<Props> = ({label, value, children})=>{
  return (
    <div style={{margin: '10px 0px'}}>
      <Text style={{
        display: 'block', 
        color: '#1C3E87',
        fontWeight: 600}}>{label}</Text>
      {typeof value === 'string' ? <Text style={{display: 'block'}}>{value}</Text>
        : value ? value : !children
        ? <Text style={{display: 'block', color: '#8C8C8C'}}>Not Set</Text>
        : <React.Fragment></React.Fragment>
      }
      {children}
    </div>
  )
}
export default DetailItem;