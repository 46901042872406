import React,{
	Suspense
} from 'react';
import useAuth from '../hooks/useAuth';
import {
	Navigate,
	Outlet
} from 'react-router-dom';
import AppLayout from '../screens/layout/AppLayout';
import { Spin } from 'antd';

const PrivateRoute: React.FC = ()=>{
	const { isLoggedIn } = useAuth();

	if(!isLoggedIn){
		return <Navigate to="/" replace />
	}

	return <AppLayout>
		<Suspense fallback={<div className="full-spin"><Spin spinning={true} /></div>}>
			<Outlet/>
		</Suspense>
	</AppLayout>
}
export default PrivateRoute;