import {
  Form,
  Input,
  message,
  Radio,
  Modal,
  Space,
  Button,
  Card,
  Spin,
  InputNumber,
  Row,
  Col,
  Select,
} from "antd";
import React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import HeaderSection from "../../components/HeaderSection";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import UploadComponent from "../../components/UploadComponent";
import { generateFormRules } from "../../helpers/formRules";
import { getErrorMessage } from "../../helpers/errorHandler";
import styled from "styled-components";
import { getImageSize } from "react-image-size";
import {
  EApplicationServiceType,
  initialPackageSubscription,
  PackageSubscriptionProperties,
} from "../../types/packageSubscription.type";
import ColorPicker from "../../components/ColorPicker";
import useFetchList from "../../hooks/useFetchList";
import { SubscriptionLevelProperties } from "../../types/subscriptionLevel.type";
import AuthContext from "../../context/AuthContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DetailItem from "../../components/DetailItem";
import useDetailBreadcrumbs from "../../hooks/useDetailBreadcrumbs";
import { showPackageSubscriptionName } from "../../helpers/subscription";
import {
  MasterLevelSubscriptionProperties,
  initialMasterLevelSubscription,
} from "../../types/user.type";

const { Option } = Select;

interface ILocation {
  levelSubscriptionId: string;
  packageSubscriptionId: string;
}

const quillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    ["clean"],
  ],
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "link",
];

interface ResponseProps
  extends BaseResponseProps<PackageSubscriptionProperties> {}

const PackageSubscriptionEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { packageSubscriptionId, levelSubscriptionId } = useParams<
    keyof ILocation
  >() as ILocation;
  const [form] = Form.useForm();

  const { setBreadcrumbDetails } = useDetailBreadcrumbs();

  const { TextArea } = Input;

  const { auth, setAuth } = React.useContext(AuthContext);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [selectedImage, setSelectedImage] = React.useState<File>();
  const [packageSubscriptionData, setPackageSubscriptionData] =
    React.useState<PackageSubscriptionProperties>(initialPackageSubscription);
  const [levelSubscriptionData, setLevelSubscriptionData] =
    React.useState<MasterLevelSubscriptionProperties>(
      initialMasterLevelSubscription
    );
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [applicationServiceType, setApplicationServiceType] =
    React.useState<string>(EApplicationServiceType.NOMINAL);

  const createPackageSubscription = async (
    value: PackageSubscriptionProperties
  ) => {
    try {
      setIsLoadingAction(true);

      const formData = {
        levelSubscriptionId,
        intervalInMonth: value.intervalInMonth,
        price: value.price,
        aliasName: value.aliasName,
        tnC: value.tnC,
        isPublished: false,
        specialPrice: value.specialPrice ? value.specialPrice : null,
        administrativeCosts: value.administrativeCosts,
        entertainmentTax: value.entertainmentTax,
        ppn: value.ppn ? value.ppn : 0,
        applicationService: value.applicationService
          ? value.applicationService
          : 0,
        applicationServiceType: value.applicationServiceType,
        createdByUserId: auth.user.userId,
      };

      const res = await httpRequest.post(
        "/master-package-subscription",
        formData
      );

      if (res.data.payload.isDuplicate) {
        setIsLoadingAction(false);
        message.error("Interval sudah terdaftar");
      } else {
        message.success("Success create " + value.aliasName);
        navigate(-1);
      }
    } catch (error: any) {
      if (getErrorMessage(error).includes("Please contact our administrator")) {
        message.error("Interval sudah terdaftar");
      } else {
        message.error(getErrorMessage(error));
      }
      setIsLoadingAction(false);
    }
  };

  const updatePackageSubscription = async (
    value: PackageSubscriptionProperties
  ) => {
    try {
      setIsLoadingAction(true);

      if (selectedImage) {
        let formData = new FormData();
        formData.append("image", selectedImage);
        await httpRequest.put(
          "/master-package-subscription/" + packageSubscriptionId + "/photo",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      const formData = {
        levelSubscriptionId: levelSubscriptionId,
        intervalInMonth: value.intervalInMonth,
        price: value.price,
        aliasName: value.aliasName,
        tnC: value.tnC,
        specialPrice: value.specialPrice,
        isPublished: value.isPublished,
        administrativeCosts: value.administrativeCosts,
        entertainmentTax: value.entertainmentTax,
        applicationService: value.applicationService
          ? value.applicationService
          : 0,
        applicationServiceType: value.applicationServiceType,
        ppn: value.ppn ? value.ppn : 0,
        createdByUserId: auth.user.userId,
      };
      await httpRequest.patch(
        "/master-package-subscription/" + packageSubscriptionId,
        formData
      );
      message.success("Success update " + value.aliasName + " data");
      navigate("/subscription-level/" + levelSubscriptionId);
    } catch (error) {
      if (getErrorMessage(error).includes("aliasName must be unique")) {
        message.error("PackageSubscription Name already registered");
      } else {
        message.error(getErrorMessage(error));
      }
      setIsLoadingAction(false);
    }
  };

  const handleSubmit = async (values: PackageSubscriptionProperties) => {
    if (packageSubscriptionId) {
      updatePackageSubscription(values);
    } else {
      createPackageSubscription(values);
    }
  };

  React.useEffect(() => {
    if (packageSubscriptionId) {
      const fetchCustomer = async () => {
        try {
          setIsLoading(true);

          const res = await httpRequest.get<ResponseProps>(
            "/master-package-subscription/" + packageSubscriptionId
          );
          setPackageSubscriptionData(res.data.payload);
          form.setFieldsValue(res.data.payload);

          const resLevel = await httpRequest.get<any>(
            "/master-level-subscription/" + levelSubscriptionId
          );
          setLevelSubscriptionData(resLevel.data.payload);

          const bcEdit = [
            {
              field: "levelSubscriptionId",
              value: levelSubscriptionId,
              label: resLevel.data.payload.name,
            },
            {
              field: "packageSubscriptionId",
              value: packageSubscriptionId,
              label: null,
            },
          ];
          setBreadcrumbDetails(bcEdit);

          if (
            res.data.payload.applicationServiceType ===
            EApplicationServiceType.PERCENTAGE
          ) {
            setApplicationServiceType(EApplicationServiceType.PERCENTAGE);
          }

          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      };

      fetchCustomer();
    } else {
      const fetchLevelData = async () => {
        try {
          setIsLoading(true);

          const resLevel = await httpRequest.get<any>(
            "/master-level-subscription/" + levelSubscriptionId
          );
          setLevelSubscriptionData(resLevel.data.payload);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      };

      fetchLevelData();
    }

    // eslint-disable-next-line
  }, [packageSubscriptionId, location]);

  const str2bool = (value: string) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  const showModalBack = () => {
    setIsModalOpen(true);
  };

  const handleOkModalBack = () => {
    navigate(-1);
    setIsModalOpen(false);
  };

  const handleCancelModalBack = () => {
    setIsModalOpen(false);
  };

  const onApplicationServiceTypeChange = (value: any) => {
    setApplicationServiceType(value);
    form.setFieldsValue({ applicationServiceType: value });
    form.setFieldsValue({ applicationService: 0 });
  };

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        backAction={showModalBack}
        title={
          (packageSubscriptionId ? "Edit" : "Add") + " Subscription Package"
        }
        subtitle={
          packageSubscriptionId
            ? "Manage subscription level data"
            : "Create new subscription level"
        }
        rightAction={
          <Space>
            <Button
              onClick={() => {
                showModalBack();
                // navigate(-1);
              }}
            >
              Cancel
            </Button>
            <Button
              loading={isLoadingAction}
              type="primary"
              onClick={() => form.submit()}
            >
              Save
            </Button>
          </Space>
        }
      />
      <Spin spinning={isLoading}>
        <Card bordered={false}>
          <Form
            form={form}
            name="packageSubscription"
            layout="vertical"
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <DetailItem
              label="Subscription Level"
              value={levelSubscriptionData.name}
            />

            <DetailItem label="Package Data" value={" "} />

            <Row gutter={16}>
              <Col>
                <Form.Item
                  style={{ width: 350 }}
                  label="Alias Name"
                  name="aliasName"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    onChange={(event) =>
                      setPackageSubscriptionData({
                        ...packageSubscriptionData,
                        aliasName: event.target.value,
                      })
                    }
                    placeholder="Input alias name"
                  />
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  label="Interval (Month)"
                  name="intervalInMonth"
                  rules={[
                    {
                      required: true,
                      message: "wajib diisi, data tidak boleh kosong",
                    },
                  ]}
                >
                  <InputNumber
                    defaultValue={packageSubscriptionData.intervalInMonth || ""}
                    min={0}
                    max={100}
                    onChange={(value: any) => {
                      setPackageSubscriptionData({
                        ...packageSubscriptionData,
                        intervalInMonth: value ? Number(value) : 0,
                      });
                    }}
                    placeholder="Input interval"
                    style={{
                      width: 150,
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col>
                <Form.Item
                  className="input-number-disable-handler"
                  label="Normal Price"
                  name="price"
                  rules={[
                    {
                      required: true,
                      message: "wajib diisi, data tidak boleh kosong",
                    },
                  ]}
                >
                  <InputNumber
                    controls={false}
                    defaultValue={packageSubscriptionData.price || 0}
                    prefix="Rp"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    min={0}
                    onChange={(value: any) => {
                      setPackageSubscriptionData({
                        ...packageSubscriptionData,
                        price: value ? Number(value) : 0,
                      });
                    }}
                    placeholder="Input price"
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  className="input-number-disable-handler"
                  label="Special Price"
                  name="specialPrice"
                  initialValue={null}
                  rules={[
                    {
                      required: false,
                      validator: (_, value) => {
                        const normalPrice = packageSubscriptionData.price || 0;
                        const specialPrice = value || 0;

                        if (specialPrice > normalPrice) {
                          return Promise.reject(
                            "Special Price tidak boleh lebih besar dari Normal Price"
                          );
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <InputNumber
                    controls={false}
                    defaultValue={packageSubscriptionData.specialPrice || 0}
                    prefix="Rp"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    min={0}
                    onChange={(value: any) => {
                      setPackageSubscriptionData({
                        ...packageSubscriptionData,
                        specialPrice: value ? Number(value) : 0,
                      });
                    }}
                    placeholder="Input special price"
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  className="input-number-disable-handler"
                  label="Biaya Admin"
                  name="administrativeCosts"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <InputNumber
                    controls={false}
                    prefix="Rp"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    defaultValue={
                      packageSubscriptionData.administrativeCosts || 0
                    }
                    min={0}
                    onChange={(value: any) => {
                      setPackageSubscriptionData({
                        ...packageSubscriptionData,
                        administrativeCosts: value ? Number(value) : 0,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  className="input-number-disable-handler"
                  label="Pajak Hiburan"
                  name="entertainmentTax"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <InputNumber
                    controls={false}
                    defaultValue={packageSubscriptionData.entertainmentTax}
                    min={0}
                    max={100}
                    onChange={(value: any) => {
                      setPackageSubscriptionData({
                        ...packageSubscriptionData,
                        entertainmentTax: value ? Number(value) : 0,
                      });
                    }}
                    formatter={(value) => `${value}%`}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  className="input-number-disable-handler"
                  label="PPN"
                  name="ppn"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <InputNumber
                    controls={false}
                    defaultValue={packageSubscriptionData.ppn}
                    min={0}
                    max={100}
                    onChange={(value: any) => {
                      setPackageSubscriptionData({
                        ...packageSubscriptionData,
                        ppn: value ? Number(value) : 0,
                      });
                    }}
                    formatter={(value) => `${value}%`}
                  />
                </Form.Item>
              </Col>
              {/* <Col>
                <Form.Item
                  className="input-number-disable-handler"
                  label="Jasa Aplikasi"
                  name="applicationService"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <InputNumber
                    controls={false}
                    defaultValue={
                      packageSubscriptionData.applicationService || 0
                    }
                    min={0}
                    onChange={(value: any) => {
                      setPackageSubscriptionData({
                        ...packageSubscriptionData,
                        applicationService: value ? Number(value) : 0,
                      });
                    }}
                  />
                </Form.Item>
              </Col> */}
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Form.Item
                  label="Tipe Jasa Aplikasi"
                  name="applicationServiceType"
                >
                  <Select
                    defaultValue={EApplicationServiceType}
                    style={{ width: "100%" }}
                    placeholder="Select Tipe Jasa Aplikasi"
                    onChange={(e) => onApplicationServiceTypeChange(e)}
                  >
                    <Option value={EApplicationServiceType.NOMINAL}>
                      {EApplicationServiceType.NOMINAL}
                    </Option>
                    <Option value={EApplicationServiceType.PERCENTAGE}>
                      {EApplicationServiceType.PERCENTAGE}
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="Jasa Aplikasi" name="applicationService">
                  {(() => {
                    if (applicationServiceType !== "") {
                      if (applicationServiceType === "NOMINAL") {
                        return (
                          <InputNumber
                            controls={false}
                            prefix="Rp"
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            onChange={(value: any) => {
                              setPackageSubscriptionData({
                                ...packageSubscriptionData,
                                applicationService: value ? Number(value) : 0,
                              });
                            }}
                            style={{ width: "100%" }}
                          />
                        );
                      } else {
                        return (
                          <InputNumber
                            controls={false}
                            onChange={(value: any) => {
                              setPackageSubscriptionData({
                                ...packageSubscriptionData,
                                applicationService: value ? Number(value) : 0,
                              });
                            }}
                            style={{ width: "100%" }}
                            min={0}
                            max={100}
                            formatter={(value) => `${value}%`}
                          />
                        );
                      }
                    }
                  })()}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label="Term and Condition"
              name="tnC"
              rules={[
                {
                  required: true,
                  message: "wajib diisi, data tidak boleh kosong",
                },
              ]}
            >
              <ReactQuill
                theme="snow"
                value={packageSubscriptionData.tnC}
                onChange={(val) =>
                  setPackageSubscriptionData({
                    ...packageSubscriptionData,
                    tnC: val,
                  })
                }
                modules={quillModules}
                formats={quillFormats}
              />
            </Form.Item>
            {/* <Form.Item
              style={{ marginTop: 10 }}
              label="Publish"
              name="isPublished"
            >
              <Radio.Group defaultValue={true}>
                <CustomRadio value={str2bool("true")}>Publish</CustomRadio>
                <CustomRadio value={str2bool("false")}>
                  Don't Publish
                </CustomRadio>
              </Radio.Group>
            </Form.Item> */}
          </Form>
        </Card>
      </Spin>

      <Modal
        title="Cancel Confirmation"
        open={isModalOpen}
        onOk={handleOkModalBack}
        onCancel={handleCancelModalBack}
      >
        <p>Are you sure ? Your data won't be save.</p>
      </Modal>
    </React.Fragment>
  );
};

export default PackageSubscriptionEdit;

const CustomRadio = styled(Radio)`
  margin-right: 1rem;
  .ant-radio-checked .ant-radio-inner {
    border-color: #264284;
    box-shadow: none;
  }
  .ant-radio:hover .ant-radio-inner {
    background-color: white;
  }
  .ant-radio-checked .ant-radio-inner:after {
    background-color: #264284;
  }
`;
