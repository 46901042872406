import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import useAuth from "../hooks/useAuth";
import { Spin } from "antd";
import EventDetail from "../screens/event/Detail";
import SubscriptionLevelEdit from "../screens/subscriptionLevel/Edit";
import SubscriptionLevelDetail from "../screens/subscriptionLevel/Detail";
import PackageSubscription from "../screens/packageSubscription";
import PackageSubscriptionEdit from "../screens/packageSubscription/Edit";
import PackageSubscriptionDetail from "../screens/packageSubscription/Detail";
// import ReportViewer from '../screens/report/viewer';
// import ReportTransaction from '../screens/report/transaction';
// import ReportVoucher from '../screens/report/voucher';
const NotFound = React.lazy(() => import("../NotFound"));

const Login = React.lazy(() => import("../screens/auth/Login"));
const LoginPhone = React.lazy(() => import("../screens/auth/LoginPhone"));
const ForgotPassword = React.lazy(
  () => import("../screens/auth/ForgotPassword")
);
const ResetPassword = React.lazy(() => import("../screens/auth/ResetPassword"));

const Dashboard = React.lazy(() => import("../screens/dashboard"));
const Profile = React.lazy(() => import("../screens/profile"));
const EditProfile = React.lazy(() => import("../screens/profile/Edit"));
const ChangeMyPassword = React.lazy(
  () => import("../screens/profile/ChangeMyPassword")
);
const ChangePasswordUser = React.lazy(
  () => import("../screens/user/ChangePassword")
);
const User = React.lazy(() => import("../screens/user"));
const UserEdit = React.lazy(() => import("../screens/user/Edit"));
const UserUploadPhoto = React.lazy(() => import("../screens/user/UploadPhoto"));
const UserDetail = React.lazy(() => import("../screens/user/Detail"));
const Tag = React.lazy(() => import("../screens/tag"));
const TagEdit = React.lazy(() => import("../screens/tag/Edit"));
const TagDetail = React.lazy(() => import("../screens/tag/Detail"));
const Role = React.lazy(() => import("../screens/role"));
const RoleEdit = React.lazy(() => import("../screens/role/Edit"));
const RoleDetail = React.lazy(() => import("../screens/role/Detail"));
const Talent = React.lazy(() => import("../screens/talent"));
const TalentEdit = React.lazy(() => import("../screens/talent/Edit"));
const TalentDetail = React.lazy(() => import("../screens/talent/Detail"));
const SubscriptionLevel = React.lazy(
  () => import("../screens/subscriptionLevel")
);
// const SubscriptionLevelEdit = React.lazy(() => import('../screens/subscriptionLevel/Edit'));
// const SubscriptionLevelDetail = React.lazy(() => import('../screens/subscriptionLevel/Detail'));
const Event = React.lazy(() => import("../screens/event"));
const EventEdit = React.lazy(() => import("../screens/event/Edit"));
// const EventDetail= React.lazy(()=>import( "../screens/event/Detail"));
const Venue = React.lazy(() => import("../screens/venue"));
const VenueEdit = React.lazy(() => import("../screens/venue/Edit"));
const VenueDetail = React.lazy(() => import("../screens/venue/Detail"));
const Review = React.lazy(() => import("../screens/review"));
const TicketClass = React.lazy(() => import("../screens/ticketClass"));
const TicketClassEdit = React.lazy(() => import("../screens/ticketClass/Edit"));
const TicketClassDetail = React.lazy(
  () => import("../screens/ticketClass/Detail")
);
const Transaction = React.lazy(() => import("../screens/transaction"));
const TransactionDetail = React.lazy(
  () => import("../screens/transaction/Detail")
);
const GeneralSetting = React.lazy(() => import("../screens/generalSetting"));
const ListIcon = React.lazy(() => import("../screens/generalSetting/listIcon"));
const ListIconEdit = React.lazy(
  () => import("../screens/generalSetting/listIcon/Edit")
);
const StaticPage = React.lazy(() => import("../screens/staticPage"));
const StaticPageEdit = React.lazy(() => import("../screens/staticPage/Edit"));
const PaymentMethod = React.lazy(() => import("../screens/paymentMethod"));
const PaymentMethodEdit = React.lazy(
  () => import("../screens/paymentMethod/Edit")
);
const Voucher = React.lazy(() => import("../screens/voucher"));
const Log = React.lazy(() => import("../screens/log"));
const VoucherEdit = React.lazy(() => import("../screens/voucher/Edit"));
const VoucherDetail = React.lazy(() => import("../screens/voucher/Detail"));
const ImportTransaction = React.lazy(
  () => import("../screens/importTransaction")
);
const ImportTransactionDetail = React.lazy(
  () => import("../screens/importTransaction/Detail")
);
const ImportTransactionErrorDetail = React.lazy(
  () => import("../screens/importTransaction/Error")
);
const Report = React.lazy(() => import("../screens/report"));
const ReportReview = React.lazy(() => import("../screens/report/review"));
const ReportTicket = React.lazy(() => import("../screens/report/ticket"));
const ReportVoucher = React.lazy(() => import("../screens/report/voucher"));
const ReportTransaction = React.lazy(
  () => import("../screens/report/transaction")
);
const ReportViewer = React.lazy(() => import("../screens/report/viewer"));

const RootNavigator: React.FC = () => {
  const { isLoggedIn } = useAuth();
  console.info("render RootNavigator, isLoggedIn: " + isLoggedIn);

  return (
    <Suspense fallback={<Spin spinning={true} />}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={isLoggedIn ? "/dashboard" : "/login"} />}
          />
          <Route element={<PublicRoute />}>
            <Route path="/login" element={<Login />} />
            <Route path="/phone-login" element={<LoginPhone />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/reset-password/:sessionId"
              element={<ResetPassword />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="*" element={<NotFound />} />
            <Route path="/dashboard" element={<Dashboard />} />

            <Route path="/profile/edit" element={<EditProfile />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/change-password" element={<ChangeMyPassword />} />

            <Route path="/user" element={<User />} />
            <Route path="/user/add" element={<UserEdit />} />
            <Route path="/user/:userId/edit" element={<UserEdit />} />
            <Route
              path="/user/:userId/upload-photo"
              element={<UserUploadPhoto />}
            />
            <Route path="/user/:userId" element={<UserDetail />} />

            <Route path="/role" element={<Role />} />
            <Route path="/role/add" element={<RoleEdit />} />
            <Route path="/role/:roleId/edit" element={<RoleEdit />} />
            <Route path="/role/:roleId" element={<RoleDetail />} />

            <Route path="/tag" element={<Tag />} />
            <Route path="/tag/add" element={<TagEdit />} />
            <Route path="/tag/:tagId/edit" element={<TagEdit />} />
            <Route path="/tag/:tagId" element={<TagDetail />} />

            <Route path="/talent" element={<Talent />} />
            <Route path="/talent/add" element={<TalentEdit />} />
            <Route path="/talent/:talentId/edit" element={<TalentEdit />} />
            <Route path="/talent/:talentId" element={<TalentDetail />} />

            <Route path="/subscription-level" element={<SubscriptionLevel />} />
            <Route
              path="/subscription-level/add"
              element={<SubscriptionLevelEdit />}
            />
            <Route
              path="/subscription-level/:levelSubscriptionId/edit"
              element={<SubscriptionLevelEdit />}
            />
            <Route
              path="/subscription-level/:levelSubscriptionId"
              element={<SubscriptionLevelDetail />}
            />

            <Route
              path="/subscription-package"
              element={<PackageSubscription />}
            />
            <Route
              path="/subscription-level/:levelSubscriptionId/add"
              element={<PackageSubscriptionEdit />}
            />
            <Route
              path="/subscription-level/:levelSubscriptionId/:packageSubscriptionId/edit"
              element={<PackageSubscriptionEdit />}
            />
            <Route
              path="/subscription-level/:levelSubscriptionId/:packageSubscriptionId"
              element={<PackageSubscriptionDetail />}
            />

            <Route path="/event" element={<Event />} />
            <Route path="/event/add" element={<EventEdit />} />
            <Route path="/event/:eventId/edit" element={<EventEdit />} />
            <Route path="/event/:eventId" element={<EventDetail />} />

            <Route path="/venue" element={<Venue />} />
            <Route path="/venue/add" element={<VenueEdit />} />
            <Route path="/venue/:venueId/edit" element={<VenueEdit />} />
            <Route path="/venue/:venueId" element={<VenueDetail />} />

            <Route path="/ticket-class" element={<TicketClass />} />
            <Route path="/ticket-class/add" element={<TicketClassEdit />} />
            <Route
              path="/ticket-class/:ticketClassId/edit"
              element={<TicketClassEdit />}
            />
            <Route
              path="/ticket-class/:ticketClassId"
              element={<TicketClassDetail />}
            />

            <Route path="/transaction" element={<Transaction />} />
            <Route
              path="/transaction/:transactionId"
              element={<TransactionDetail />}
            />

            <Route path="/voucher" element={<Voucher />} />
            <Route path="/voucher/add" element={<VoucherEdit />} />
            <Route path="/voucher/:voucherId/edit" element={<VoucherEdit />} />
            <Route path="/voucher/:voucherId" element={<VoucherDetail />} />

            <Route path="/general-setting" element={<GeneralSetting />} />
            <Route path="/general-setting/list-icon" element={<ListIcon />} />
            <Route
              path="/general-setting/list-icon/:scheduleId/edit"
              element={<ListIconEdit />}
            />
            <Route
              path="/general-setting/list-icon/add"
              element={<ListIconEdit />}
            />

            <Route path="/static-page" element={<StaticPage />} />
            <Route
              path="/static-page/:configId/edit"
              element={<StaticPageEdit />}
            />

            <Route path="/payment-method" element={<PaymentMethod />} />
            <Route path="/payment-method/add" element={<PaymentMethodEdit />} />
            <Route
              path="/payment-method/:paymentMethodId/edit"
              element={<PaymentMethodEdit />}
            />

            <Route path="/event-review" element={<Review />} />

            <Route path="/log-activity" element={<Log />} />

            <Route path="/import" element={<ImportTransaction />} />
            <Route
              path="/import/:importId"
              element={<ImportTransactionDetail />}
            />
            <Route
              path="/import/:importId/error-detail"
              element={<ImportTransactionErrorDetail />}
            />

            <Route path="/report" element={<Report />} />
            <Route path="/report/report-review" element={<ReportReview />} />
            <Route path="/report/report-ticket" element={<ReportTicket />} />
            <Route path="/report/report-viewer" element={<ReportViewer />} />
            <Route
              path="/report/report-transaction"
              element={<ReportTransaction />}
            />
            <Route path="/report/report-voucher" element={<ReportVoucher />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};
export default RootNavigator;
