import { MasterLevelSubscriptionProperties } from "./user.type";

export enum EApplicationServiceType {
  NOMINAL = "NOMINAL",
  PERCENTAGE = "PERCENTAGE",
}

export type PackageSubscriptionProperties = {
  packageSubscriptionId?: string;
  levelSubscriptionId: string;
  intervalInMonth: number;
  price: number;
  specialPrice: number;
  aliasName: string;
  tnC: string;
  administrativeCosts: number;
  entertainmentTax: number;
  ppn: number;
  applicationService: number;
  applicationServiceType: string;
  isPublished: boolean;
  isSoftDelete: boolean;
  createdAt: Date | string;
  updatedAt: Date | string;
  createdByUserId: string;

  levelSubscription?: MasterLevelSubscriptionProperties;
};

export const initialPackageSubscription: PackageSubscriptionProperties = {
  packageSubscriptionId: "",
  levelSubscriptionId: "",
  intervalInMonth: 0,
  price: 0,
  specialPrice: 0,
  aliasName: "",
  tnC: "",
  administrativeCosts: 0,
  entertainmentTax: 0,
  ppn: 0,
  applicationService: 0,
  applicationServiceType: "",
  isPublished: true,
  isSoftDelete: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  createdByUserId: "",
};
