import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MoreOutlined,
  SearchOutlined,
  AudioOutlined,
  PlusOutlined,
  EyeFilled,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Space,
  Table,
  message,
  Select,
  Input,
  Modal,
  Switch,
  Dropdown,
  Menu,
  Button,
  Card,
  Tag,
} from "antd";
import HeaderSection from "../../components/HeaderSection";
import {
  EApplicationServiceType,
  PackageSubscriptionProperties,
  initialPackageSubscription,
} from "../../types/packageSubscription.type";
import useFetchList from "../../hooks/useFetchList";
import { BaseResponseProps } from "../../types/config.type";
import { httpRequest } from "../../helpers/api";
import { formatDate } from "../../helpers/constant";
import type { TableProps } from "antd";
import styled from "styled-components";
import useAuth from "../../hooks/useAuth";
import {
  showIntervalInMonthText,
  showIntervalInMonthTextPlusAliasName,
  showPackageSubscriptionName,
} from "../../helpers/subscription";
import { priceFormat } from "../../helpers/priceFormat";
import NotSet from "../../components/NotSet";
import Title from "antd/lib/typography/Title";

interface ResponseProps
  extends BaseResponseProps<PackageSubscriptionProperties> {}

type Props = {
  levelSubscriptionId?: string;
};

const PackageSubscription = (props: Props) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [isShowModalTnC, setIsShowModalTnC] = useState(false);

  const {
    isLoading,
    data,
    fetchList,
    setData,
    setSearch,
    setQuery,
    pagination,
    changePage,
  } = useFetchList<PackageSubscriptionProperties>({
    endpoint: "master-package-subscription",
    limit: 100,
    initialQuery: {
      levelSubscriptionId: props.levelSubscriptionId,
    },
  });

  const handleIsPublishedChange = async () => {
    try {
      setIsLoadingUpdate(true);
      const permissions = user.roles.map(
        (item) =>
          item.permissions["MASTER_PACKAGE_SUBSCRIPTION"]?.["UPDATE"]["value"]
      );
      if (permissions.includes(true)) {
        let newData = [];
        newData = data.map((item) => {
          if (item.packageSubscriptionId === tmpData.packageSubscriptionId) {
            return {
              ...item,
              isPublishedLoading: true,
            };
          }
          return item;
        });

        const newIsPublished = tmpData.isPublished ? false : true;

        const res = await httpRequest.patch<ResponseProps>(
          "/master-package-subscription/" + tmpData.packageSubscriptionId,
          {
            packageSubscriptionId: tmpData.packageSubscriptionId,
            isPublished: newIsPublished,
          }
        );

        newData = data.map((item) => {
          if (
            item.packageSubscriptionId ===
            res.data.payload.packageSubscriptionId
          ) {
            return {
              ...item,
              isPublished: res.data.payload.isPublished,
              isPublishedLoading: false,
            };
          }
          return item;
        });
        setData(newData);
        fetchList();

        message.success(
          "Success change " + tmpData.aliasName + " published status."
        );
        setIsModalVisible(false);
        setTmpData(initialPackageSubscription);
        setIsLoadingUpdate(false);
      } else {
        message.error("Your account don't have permission to update data.");
        setIsModalVisible(false);
        setTmpData(initialPackageSubscription);
        setIsLoadingUpdate(false);
      }
    } catch (error: any) {
      message.error(error.data.message);
      setIsModalVisible(false);
      setTmpData(initialPackageSubscription);
      setIsLoadingUpdate(false);
    }
  };

  const handleDelete = async () => {
    try {
      const permissions = user.roles.map(
        (item) =>
          item.permissions["MASTER_PACKAGE_SUBSCRIPTION"]?.["DELETE"]["value"]
      );
      if (permissions.includes(true)) {
        let newData = [];
        await httpRequest.delete<ResponseProps>(
          "/master-package-subscription/" + tmpData.packageSubscriptionId
        );
        newData = data.filter((item) => {
          return item.packageSubscriptionId !== tmpData.packageSubscriptionId;
        });
        setData(newData);
        message.success("Success delete " + tmpData.aliasName);
        setIsModalVisible(false);
        setTmpData(initialPackageSubscription);
        setIsLoadingUpdate(false);
      } else {
        message.error("Your account don't have permission to delete data.");
        setIsModalVisible(false);
        setTmpData(initialPackageSubscription);
        setIsLoadingUpdate(false);
      }
    } catch (error: any) {
      message.error(error.data.message);
      setIsModalVisible(false);
      setTmpData(initialPackageSubscription);
      setIsLoadingUpdate(false);
    }
  };

  const handleSoftDelete = async () => {
    try {
      const permissions = user.roles.map(
        (item) =>
          item.permissions["MASTER_PACKAGE_SUBSCRIPTION"]?.["DELETE"]["value"]
      );
      if (permissions.includes(true)) {
        const res = await httpRequest.delete<any>(
          "/master-package-subscription/" +
            tmpData.packageSubscriptionId +
            "/request"
        );

        fetchList();

        message.success("Success soft delete " + tmpData.aliasName);
        setIsModalVisible(false);
        setTmpData(initialPackageSubscription);
      } else {
        message.error(
          "Your account don't have permission to delete packageSubscriptions."
        );
        // console.log(permissions);
        setIsModalVisible(false);
        setTmpData(initialPackageSubscription);
      }
    } catch (error: any) {
      message.error(error.data.message);
      setIsModalVisible(false);
      setTmpData(initialPackageSubscription);
    }
  };

  const handleActivatePackageSubscription = async () => {
    try {
      setIsLoadingUpdate(true);
      const permissions = user.roles.map(
        (item) =>
          item.permissions["MASTER_PACKAGE_SUBSCRIPTION"]?.["UPDATE"]["value"]
      );
      if (permissions.includes(true)) {
        const res = await httpRequest.patch<ResponseProps>(
          "/master-package-subscription/" + tmpData.packageSubscriptionId,
          {
            ...tmpData,
            isPublished: true,
            isSoftDelete: false,
          }
        );

        fetchList();

        message.success("Success change " + tmpData.aliasName + " status.");
        setIsModalVisible(false);
        setTmpData(initialPackageSubscription);
        setIsLoadingUpdate(false);
      } else {
        message.error(
          "Your account don't have permission to update package subscriptions."
        );
        setIsModalVisible(false);
        setTmpData(initialPackageSubscription);
        setIsLoadingUpdate(false);
      }
    } catch (error: any) {
      message.error(error.data.message);
      setIsModalVisible(false);
      setTmpData(initialPackageSubscription);
      setIsLoadingUpdate(false);
    }
  };

  const { Option } = Select;

  const handleChangeStatus = (status: any) => {
    if (status !== "all") {
      setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, isPublished: undefined }));
    }
  };

  const columns = [
    {
      title: "Interval",
      align: "center",
      dataIndex: "intervalInMonth",
      width: "150",
      maxWidth: "150",
      key: "intervalInMonth",
      render: (
        intervalInMonth: number,
        record: PackageSubscriptionProperties
      ) => {
        return (
          <div
          // style={{ textDecoration: 'underline' }}
          // onClick={() => {
          //   setTmpData(record);
          //   setIsShowModalTnC(true);
          // }}
          >
            {showIntervalInMonthText(intervalInMonth)}
          </div>
        );
      },
    },
    {
      title: "Alias Name",
      dataIndex: "aliasName",
      key: "aliasName",
      render: (aliasName: string) => (aliasName ? aliasName : <NotSet />),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price: number, record: PackageSubscriptionProperties) => (
        <>
          {(() => {
            if (!record.specialPrice) {
              return <div>{priceFormat(record.price)}</div>;
            } else if (record.price === record.specialPrice) {
              return <div>{priceFormat(record.specialPrice)}</div>;
            } else if (record.price !== record.specialPrice) {
              return (
                <div>
                  <p
                    style={{
                      textDecoration: "line-through",
                      marginBottom: "0",
                      color: "#D8D7D7",
                    }}
                  >
                    {priceFormat(record.price)}
                  </p>
                  {priceFormat(record.specialPrice)}
                </div>
              );
            }
          })()}
        </>
      ),
    },
    {
      title: "Additional Fees",
      dataIndex: "administrativeCosts",
      key: "administrativeCosts",
      render: (price: number, record: PackageSubscriptionProperties) => (
        <div>
          Biaya Admin : {priceFormat(record.administrativeCosts)}
          <br />
          Pajak Hiburan: {record.entertainmentTax || 0}%
          <br />
          Jasa Aplikasi:{" "}
          {record.applicationServiceType === EApplicationServiceType.NOMINAL ||
          record.applicationServiceType === "" ||
          record.applicationService === null
            ? priceFormat(record.applicationService)
            : `${record.applicationService || 0}%`}
          <br />
          PPN: {record.ppn || 0}%
        </div>
      ),
    },
    {
      title: "Term and Condition",
      dataIndex: "tnC",
      key: "tnC",
      render: (tnC: string, record: PackageSubscriptionProperties) => (
        <Button
          type="link"
          onClick={() => {
            setTmpData(record);
            setIsShowModalTnC(true);
          }}
        >
          <EyeFilled /> View
        </Button>
      ),
    },
    {
      title: "Status",
      key: "isPublished",
      dataIndex: "isPublished",
      align: "center",
      render: (isPublished: any, record: PackageSubscriptionProperties) => (
        <>
          {(() => {
            if (!record.isSoftDelete) {
              return (
                <Switch
                  checked={isPublished === true}
                  onChange={() => {
                    const permissions = user.roles.map(
                      (item) =>
                        item.permissions["MASTER_PACKAGE_SUBSCRIPTION"]?.[
                          "UPDATE"
                        ]["value"]
                    );
                    if (permissions.includes(true)) {
                      setConfirmationType("isPublished");
                      setIsModalVisible(true);
                      setTmpData(record);
                    } else {
                      message.error("Your account don't have permissions.");
                    }
                  }}
                />
              );
            } else {
              return (
                <Tag
                  style={{
                    border: "2px solid #D81F64",
                    color: "#D81F64",
                    marginBottom: "7%",
                  }}
                >
                  Deleted
                </Tag>
              );
            }
          })()}
        </>
      ),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
    },
    {
      title: "Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      render: (updatedAt: any) => <div>{formatDate(updatedAt)}</div>,
    },
    {
      title: "",
      key: "action",
      render: (_: any, record: PackageSubscriptionProperties) => (
        <Dropdown overlay={() => menu(record)} placement="bottomRight">
          <MoreOutlined style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
    },
  ] as TableProps<PackageSubscriptionProperties>["columns"];
  const [isLoadingUpdate, setIsLoadingUpdate] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [tmpData, setTmpData] = React.useState<PackageSubscriptionProperties>(
    initialPackageSubscription
  );
  const [confirmationType, setConfirmationType] = React.useState<string>("");

  const handleCreatePackageSubscription = () => {
    navigate({
      pathname: "/subscription-level/" + props.levelSubscriptionId + "/add",
    });
  };

  const handleClick = (key: string, record: PackageSubscriptionProperties) => {
    if (key === "edit") {
      navigate({
        pathname:
          "/subscription-level/" +
          props.levelSubscriptionId +
          "/" +
          record.packageSubscriptionId +
          "/edit",
      });
    } else if (key === "delete") {
      setConfirmationType("delete");
      setIsModalVisible(true);
      setTmpData(record);
    } else if (key === "softDelete") {
      setTmpData(record);
      setConfirmationType("softDelete");
      setIsModalVisible(true);
    } else if (key === "activatePackageSubscription") {
      setTmpData(record);
      setConfirmationType("activatePackageSubscription");
      setIsModalVisible(true);
    } else if (key === "delete") {
      setConfirmationType("delete");
      setIsModalVisible(true);
      setTmpData(record);
    }
  };

  const menu = (record: PackageSubscriptionProperties) => (
    <Menu onClick={(e) => handleClick(e.key, record)}>
      {user.roles
        .map(
          (item) =>
            item.permissions["MASTER_PACKAGE_SUBSCRIPTION"]?.["UPDATE"]["value"]
        )
        .includes(true) ? (
        <Menu.Item key="edit">Edit</Menu.Item>
      ) : (
        false
      )}
      {user.roles
        .map(
          (item) =>
            item.permissions["MASTER_PACKAGE_SUBSCRIPTION"]?.["DELETE"]["value"]
        )
        .includes(true) ? (
        !record.isSoftDelete ? (
          <Menu.Item key="softDelete">Soft Delete</Menu.Item>
        ) : (
          false
        )
      ) : (
        false
      )}
      {user.roles
        .map(
          (item) =>
            item.permissions["MASTER_PACKAGE_SUBSCRIPTION"]?.["DELETE"]["value"]
        )
        .includes(true) ? (
        record.isSoftDelete ? (
          <Menu.Item key="delete">Hard Delete</Menu.Item>
        ) : (
          false
        )
      ) : (
        false
      )}
      {user.roles
        .map(
          (item) =>
            item.permissions["MASTER_PACKAGE_SUBSCRIPTION"]?.["DELETE"]["value"]
        )
        .includes(true) ? (
        record.isSoftDelete ? (
          <Menu.Item key="activatePackageSubscription">
            Activate Subscription Package
          </Menu.Item>
        ) : (
          false
        )
      ) : (
        false
      )}
    </Menu>
  );
  const rightAction: any = user.roles
    .map(
      (item) =>
        item.permissions["MASTER_PACKAGE_SUBSCRIPTION"]?.["CREATE"]["value"]
    )
    .includes(true) ? (
    <Button
      icon={<PlusOutlined />}
      style={{ background: "#264284", color: "white" }}
      onClick={handleCreatePackageSubscription}
    >
      Add Package
    </Button>
  ) : (
    false
  );
  return (
    <React.Fragment>
      <Row gutter={2}>
        <Col>
          <Title level={4}>Packages</Title>
        </Col>
        <Col flex="auto" style={{ textAlign: "right", marginBottom: 10 }}>
          {rightAction}
        </Col>
      </Row>

      {/* <ContainerFilter>
        <Input
          style={{ width: 300, marginLeft: 20 }}
          className="outer-shadow"
          placeholder="Search by Name or Description"
          suffix={<SearchOutlined />}
          allowClear
          onChange={(e) => {
            const permissions = user.roles.map(
              (item) =>
                item.permissions['MASTER_PACKAGE_SUBSCRIPTION']?.['LIST'][
                  'value'
                ]
            );
            if (permissions.includes(true)) {
              setSearch(e.target.value);
            } else {
              message.error("Your account don't have permission.");
            }
          }}
        />
        <Select
          className="outer-shadow"
          style={{ width: 160 }}
          onChange={handleChangeStatus}
          placeholder="Published"
        >
          <Option value="all">All</Option>
          <Option value={true}>Published</Option>
          <Option value={false}>Not Published</Option>
        </Select>
      </ContainerFilter> */}

      <Table
        rowKey="packageSubscriptionId"
        loading={isLoading}
        columns={columns}
        dataSource={
          user.roles
            .map(
              (item) =>
                item.permissions["MASTER_PACKAGE_SUBSCRIPTION"]?.["LIST"][
                  "value"
                ]
            )
            .includes(true)
            ? data
            : undefined
        }
        pagination={false}
        // pagination={{
        //   current: pagination.page,
        //   total: pagination.totalData,
        //   defaultPageSize: pagination.perPage,
        //   pageSize: pagination.perPage,
        //   onChange: changePage,
        // }}
      />
      {(() => {
        if (confirmationType === "isPublished") {
          return (
            <Modal
              title="Update isPublished confirmation"
              open={isModalVisible}
              onOk={handleIsPublishedChange}
              onCancel={() => {
                setIsModalVisible(false);
                setTmpData(initialPackageSubscription);
              }}
              okText="Yes"
              confirmLoading={isLoadingUpdate}
              okButtonProps={{ type: "primary" }}
            >
              <p>
                Are you sure want to change{" "}
                <b>
                  "
                  {showIntervalInMonthTextPlusAliasName(
                    tmpData.intervalInMonth,
                    tmpData.aliasName
                  )}
                  "
                </b>{" "}
                status to{" "}
                <b>"{tmpData.isPublished ? "Not Publish" : "Publish"}"</b>?.{" "}
                {tmpData.isPublished
                  ? 'If this package status is changed to "Not Publish", then this package cannot be seen'
                  : 'If this package status is changed to "Publish", then this package can be seen'}
              </p>
            </Modal>
          );
        } else if (confirmationType === "softDelete") {
          return (
            <Modal
              title="Soft delete confirmation"
              open={isModalVisible}
              onOk={handleSoftDelete}
              onCancel={() => {
                setIsModalVisible(false);
                setTmpData(initialPackageSubscription);
              }}
              okText="Yes"
              confirmLoading={isLoadingUpdate}
              okButtonProps={{ type: "primary" }}
            >
              <p>
                Are you sure want to soft delete{" "}
                <b>
                  "
                  {showIntervalInMonthTextPlusAliasName(
                    tmpData.intervalInMonth,
                    tmpData.aliasName
                  )}
                  "
                </b>{" "}
                ?
              </p>
            </Modal>
          );
        }
        if (confirmationType === "activatePackageSubscription") {
          return (
            <Modal
              title="Activate packageSubscription confirmation"
              open={isModalVisible}
              onOk={handleActivatePackageSubscription}
              onCancel={() => {
                setIsModalVisible(false);
                setTmpData(initialPackageSubscription);
              }}
              okText="Yes"
              confirmLoading={isLoadingUpdate}
              okButtonProps={{ type: "primary" }}
            >
              <p>
                Are you sure want to change{" "}
                <b>
                  "
                  {showIntervalInMonthTextPlusAliasName(
                    tmpData.intervalInMonth,
                    tmpData.aliasName
                  )}
                  "
                </b>{" "}
                status to <b>"Active"</b>?. If this subscription package status
                is changed to "Active", then this subscription package can be
                use
              </p>
            </Modal>
          );
        } else {
          return (
            <Modal
              title="Hard delete confirmation"
              open={isModalVisible}
              onOk={handleDelete}
              onCancel={() => {
                setIsModalVisible(false);
                setTmpData(initialPackageSubscription);
              }}
              okText="Yes"
              confirmLoading={isLoadingUpdate}
              okButtonProps={{ type: "primary" }}
            >
              <p>
                Are you sure want to hard delete{" "}
                <b>
                  "
                  {showIntervalInMonthTextPlusAliasName(
                    tmpData.intervalInMonth,
                    tmpData.aliasName
                  )}
                  "
                </b>{" "}
                ?
              </p>
            </Modal>
          );
        }
      })()}

      {isShowModalTnC && (
        <Modal
          title="Term and Condition"
          open={isShowModalTnC}
          closable={false}
          width={600}
          footer={null}
          onCancel={() => {
            setIsShowModalTnC(false);
            setTmpData(initialPackageSubscription);
          }}
          okButtonProps={{ type: "primary" }}
        >
          <div dangerouslySetInnerHTML={{ __html: tmpData.tnC }}></div>
        </Modal>
      )}
    </React.Fragment>
  );
};
export default PackageSubscription;

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
`;
