import React from 'react';
import RootNavigator from './navigation/RootNavigator';
import './assets/app.less';
import AuthProvider from './context/AuthProvider';
import ConfigProvider from './context/ConfigProvider';
import { Alert } from 'antd';
import { ThemeProvider } from 'styled-components';
import { theme } from './assets/theme';
// import { GoogleOAuthProvider } from '@react-oauth/google';

const { ErrorBoundary } = Alert;

function App() {
  console.info('render App');
  
  return (
    <ErrorBoundary>
      <ConfigProvider>
        <ThemeProvider theme={theme}>
          {/* <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}> */}
            <AuthProvider>
              <RootNavigator />
            </AuthProvider>
          {/* </GoogleOAuthProvider> */}
        </ThemeProvider>
      </ConfigProvider>
    </ErrorBoundary>
  );
}

export default App;
