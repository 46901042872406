import {
  Divider,
  Space,
  message,
  Button,
  Modal,
  Card,
  Row,
  Col,
  Image,
  Spin,
} from "antd";
import React from "react";
import HeaderSection from "../../components/HeaderSection";
import { useParams, useNavigate } from "react-router-dom";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import useDetailBreadcrumbs from "../../hooks/useDetailBreadcrumbs";
import DetailItem from "../../components/DetailItem";
import {
  initialSubscriptionLevel,
  SubscriptionLevelProperties,
} from "../../types/subscriptionLevel.type";
import { formatDate } from "../../helpers/constant";
import PackageSubscription from "../packageSubscription";
import NotSet from "../../components/NotSet";

interface ILocation {
  levelSubscriptionId: string;
}

interface ResponseProps
  extends BaseResponseProps<SubscriptionLevelProperties> {}

const SubscriptionLevelDetail = () => {
  const navigate = useNavigate();
  const { levelSubscriptionId } = useParams<keyof ILocation>() as ILocation;
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [subscriptionLevel, setSubscriptionLevel] =
    React.useState<SubscriptionLevelProperties>(initialSubscriptionLevel);
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [confirmationType, setConfirmationType] = React.useState<string>("");

  React.useEffect(() => {
    const fetchSubscriptionLevel = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<ResponseProps>(
          "/master-level-subscription/" + levelSubscriptionId
        );

        // console.log(res.data.payload);
        setSubscriptionLevel(res.data.payload);

        const bcDetails = [
          {
            field: "levelSubscriptionId",
            value: levelSubscriptionId,
            label: res.data.payload.name,
          },
        ];
        setBreadcrumbDetails(bcDetails);

        setIsLoading(false);
      } catch (error: any) {
        message.error(error);
        setIsLoading(false);
      }
    };
    fetchSubscriptionLevel();

    // eslint-disable-next-line
  }, [levelSubscriptionId]);

  const handleClickEdit = () => {
    navigate("/subscription-level/" + levelSubscriptionId + "/edit");
  };

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={subscriptionLevel.name}
        backAction={() => navigate("/subscription-level")}
        subtitle="Manage subscription level"
        rightAction={
          <Space>
            {/* <Button onClick={() => navigate("/subscription-level")}>
              Cancel
            </Button> */}
            <Button type="primary" onClick={handleClickEdit}>
              Edit
            </Button>
          </Space>
        }
      />
      <Spin spinning={isLoading}>
        <Card bordered={false}>
          <Row gutter={[24, 0]}>
            <Col span={40}>
              <Image
                preview={false}
                width={200}
                src={
                  subscriptionLevel.icon
                    ? subscriptionLevel.icon
                    : "/images/no-preview.jpg"
                }
                fallback={"/images/blur-image.jpeg"}
                style={{ objectFit: "cover" }}
                placeholder={true}
              />
            </Col>
            <Col span={10}>
              <DetailItem label="Name" value={subscriptionLevel.name} />
              <DetailItem
                label="Level"
                value={subscriptionLevel.level.toString()}
              />
              <DetailItem
                label="Warna Start"
                children={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <div
                      style={{
                        height: 15,
                        width: 15,
                        borderRadius: "50%",
                        backgroundColor: subscriptionLevel.startColor,
                      }}
                    />
                    <div>{subscriptionLevel.startColor.toUpperCase()}</div>
                  </div>
                }
              />
              <DetailItem
                label="Warna End"
                children={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <div
                      style={{
                        height: 15,
                        width: 15,
                        borderRadius: "50%",
                        backgroundColor: subscriptionLevel.endColor,
                      }}
                    />
                    <div>{subscriptionLevel.endColor.toUpperCase()}</div>
                  </div>
                }
              />
              <DetailItem
                label="Description"
                children={
                  <div
                    className="table-link"
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      setConfirmationType("description");
                      setIsModalVisible(true);
                    }}
                  >
                    {subscriptionLevel.description ? (
                      subscriptionLevel.description.length > 400 ? (
                        subscriptionLevel.description
                          .split(/\s+/, 75)
                          .join(" ") + "..."
                      ) : (
                        subscriptionLevel.description
                      )
                    ) : (
                      <NotSet />
                    )}
                  </div>
                }
              />
              <DetailItem
                label="Status"
                value={subscriptionLevel.isPublished ? "Active" : "Inactive"}
              />
              <DetailItem
                label="Created At"
                value={formatDate(subscriptionLevel.createdAt)}
              />
              <DetailItem
                label="Updated At"
                value={formatDate(subscriptionLevel.updatedAt)}
              />
            </Col>
          </Row>
        </Card>
        {confirmationType === "description" ? (
          <Modal
            title="Description"
            open={isModalVisible}
            onCancel={() => {
              setIsModalVisible(false);
            }}
            footer={null}
          >
            <div style={{ textAlign: "center" }}>
              {subscriptionLevel.description}
            </div>
          </Modal>
        ) : (
          <></>
        )}

        <Card bordered={false} style={{ marginTop: 10 }}>
          <PackageSubscription levelSubscriptionId={levelSubscriptionId} />
        </Card>
      </Spin>
    </React.Fragment>
  );
};

export default SubscriptionLevelDetail;
