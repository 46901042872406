import { Avatar, Image } from 'antd'
import React from 'react'
import styled from 'styled-components'

type AppLogoProps = {
  collapsed: boolean
}

const AppLogo: React.FC<AppLogoProps> = ({ collapsed }) => {
  return (
    <LogoEtanaContainer 
    style={{marginBottom: 40}}
    collapsed={collapsed}>
      {collapsed ? (
        <AppAvatar size="large">APP</AppAvatar>
      ) : (
        <AppTitle>
          {/* {process.env.REACT_APP_WEBSITE_NAME} */}
          <Image
            preview={false}
            width={60}
            src={'/images/logo.svg'}
            style={{ 
              objectFit: 'cover', 
              marginTop: 30 }}
          />
        </AppTitle>
      )}
    </LogoEtanaContainer>
  );
}

type LogoEtanaContainerProps = {
  collapsed: boolean
}

const LogoEtanaContainer = styled.div<LogoEtanaContainerProps>`
  padding: ${({ collapsed }) => collapsed ? '0' : '0 16px'};
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: ${({ collapsed }) => collapsed ? 'center' : 'flex-start'};
  background-color: ${({ theme }) => theme.colors.primary};
`

const AppTitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

const AppAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

export default AppLogo